import { browserHistory } from '../../../store/utils/history';
import { AccountQuery_viewer_account_byEmail } from '../../../use-cases/settings/graphql/gql/AccountQuery';
import { Location } from '../../routes/AppRoutes';
import { getMainPage } from '../../routes/routeUtils';
import { getModalRouteFromPath, redirectModal } from '../../routes/tryModal';

export function redirectDependingOnPermissions(
  account: AccountQuery_viewer_account_byEmail,
  isNewSession: boolean,
) {
  const currentLocation = browserHistory.location;
  const newLocation: Location = {
    pathname:
      new URLSearchParams(window.location.search).get('returnTo') ||
      getMainPage(account.permissions),
  };

  // Redirect only if we just logged in. Otherwise, we would redirect on new tabs etc.
  const location = isNewSession ? newLocation : currentLocation;

  const modalRoute = getModalRouteFromPath(location);

  if (modalRoute) {
    redirectModal(modalRoute, location);
  } else {
    browserHistory.replace(location);
  }
}
