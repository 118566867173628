import { FlatButtonLink, stenaCheck, stenaPen } from '@stenajs-webui/elements';
import { useSelector } from 'react-redux';
import { TrackerCategory, trackEvent } from '../../common/tracking/trackerService';
import { resolveCustomsDocumentationEnsURL } from '../../common/utils/customsEnsUtils';
import { getUserNameWithPrefix } from '../../services/authentication/authReducer';
import { getCookieConsent } from '../../services/cookie-consent/slice';

interface EnsButtonLinkProps {
  ensUrl: string;
  confirmed: boolean;
  optional?: boolean;
  trackerCategory: TrackerCategory;
}

export function EnsButtonLink({
  ensUrl,
  confirmed,
  optional,
  trackerCategory,
}: EnsButtonLinkProps) {
  const userId = useSelector(getUserNameWithPrefix);
  const consent = useSelector(getCookieConsent);

  const onClick = () => {
    trackEvent({ category: trackerCategory, action: 'Pressed ENS link' });
  };

  return (
    <FlatButtonLink
      className={'t_ens_button_link'}
      target={'_blank'}
      href={resolveCustomsDocumentationEnsURL(ensUrl, userId, consent)}
      onClick={onClick}
      label={confirmed ? 'ENS Confirmed' : optional ? 'Declare ENS (optional)' : 'Declare ENS'}
      leftIcon={confirmed ? stenaCheck : stenaPen}
    />
  );
}
