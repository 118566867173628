import { PropsWithChildren } from 'react';
import { setFilterFromUrlWithSettingsFallback } from '../../../services/booking-filter/thunks/setFilterFromUrlWithSettingsFallback';
import { transformDefaultFiltersFromSettings } from '../../../services/booking-filter/utils/filterUtils';
import { rehydratePageSettings } from '../../../services/page-settings/pageSettingsActions';
import { transformToPageSettingsRehydratePayload } from '../../../services/page-settings/sagas/utils/transformToPageSettingsRehydratePayload';
import { transformToTourReducerState } from '../../../services/page-settings/sagas/utils/transformToTourReducerState';
import { initializeSettingsForm, setAccount } from '../../../services/settings/settingsReducer';
import { requestInitializeTour } from '../../../services/tour/tourActions';
import { useAppDispatch } from '../../../store/appDispatch';
import { transformToSettingsFormState } from '../../../use-cases/settings/utils/transformToSettingsFormState';
import { suspend } from '../../../vendor/suspend-react';
import { fetchAccount } from '../../api';
import { assertIsDefined } from '../../asserts/assert';
import { useAuthState } from '../../hooks/useAuthState';
import { redirectDependingOnPermissions } from '../utils/utils';

export const AccountSettingsGate = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { isNewSession } = useAuthState();

  suspend(async () => {
    const account = await fetchAccount();

    assertIsDefined(account, 'Error fetching user account');

    const { accountSettings, pageSettings } = account;

    dispatch(setAccount(account));
    dispatch(initializeSettingsForm(transformToSettingsFormState(accountSettings)));
    dispatch(rehydratePageSettings(transformToPageSettingsRehydratePayload(pageSettings)));
    dispatch(
      setFilterFromUrlWithSettingsFallback(
        transformDefaultFiltersFromSettings(account.accountSettings),
      ),
    );

    redirectDependingOnPermissions(account, isNewSession);

    dispatch(requestInitializeTour(transformToTourReducerState(pageSettings.tour)));
  }, ['account']);

  return children;
};
