import { Box, Column, Heading, Row, Txt } from '@stenajs-webui/core';
import { cssColor } from '@stenajs-webui/theme';
import { PropsWithChildren } from 'react';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { ImportExportType } from '../../../../gql/graphql';
import {
  getImportExportSettingsFromMinimalBooking,
  getImportExportSettingsFromRoute,
} from '../../../import-export/util/ImportExportSettingsGetters';
import { useUpdateFormStateMinimalBooking } from '../utils/minimalBooking';

interface OptionalEnsCardProps extends PropsWithChildren {
  booking: BasicBooking;
}

export function OptionalEnsCard({ booking, children }: OptionalEnsCardProps) {
  const minimalBooking = useUpdateFormStateMinimalBooking();

  const importSettings = getImportExportSettingsFromMinimalBooking(
    minimalBooking,
    getImportExportSettingsFromRoute(minimalBooking?.route, 'importReference'),
  );

  if (
    importSettings?.type === ImportExportType.EnvelopeId &&
    importSettings.showEnsLink &&
    (booking.editable || booking.importReference.ensConfirmed)
  ) {
    return (
      <Box
        borderRadius={16}
        background={cssColor('--lhds-color-ui-50')}
        gap={1}
        spacing={2}
        indent={3}
        className={'t_optional_ens_card'}
      >
        <Row gap={1} alignItems={'center'}>
          <img src={'/img/flags/flags-iso/flat/svg/gb.svg'} alt={''} width={24} height={18} />
          <Heading variant={'h5'}>Faster UK import?</Heading>
        </Row>
        <Column gap={1}>
          <Txt size={'small'}>
            Complete ENS at Stena Line and speed up your UK pre-lodgement movement.
          </Txt>
          {children}
        </Column>
      </Box>
    );
  }

  return null;
}
